.edocuments-table-style {
  .Polaris-DataTable__Cell, .Polaris-DataTable__Cell--header {
    min-width: 150px;
    max-width: 250px;
    white-space: wrap;
    padding-left: 10px;
    &:first-child {
      min-width: 40px;
      max-width: 40px;
      white-space: wrap;
      padding: 10px;
    }
  }

}