html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(249, 249, 249, 1)!important;
  color: rgba(46, 46, 46, 1);
}
h1, h2, h3, h4, h5, h6, p, span{
  color: rgba(46, 46, 46, 1);
}
:root {
  --p-color-text-primary: rgba(38, 127, 215, 1);
  --p-color-action-primary: rgba(38, 127, 215, 1);
  --p-color-action-variantMonochromePlain: rgba(240, 68, 56, 1);
  --p-color-action-primary-hover: rgba(44, 145, 234, 1);
  --p-color-action-primary-active: rgba(44, 145, 234, 1);
  --p-color-action-primary-disabled: rgba(149, 207, 252, 1);
  --p-space-4: 0.85rem;
  --pc-button-bg_hover: rgba(44, 145, 234, 1);
  --pc-button-bg_active: rgba(44, 145, 234, 1);
  --pc-bg-hover: rgba(245, 250, 255, 1);
  --pc-shadow-bevel-border-radius: 6px;
  --p-color-text-secondary: rgba(46, 46, 46, 1);
  --a-link: rgba(44, 110, 203, 1);
  --p-text-body-md-font-size: 14px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

.Polaris-TextField__Spinner {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.rc-pagination {
  text-align: end;
}
.Polaris-Label{
  .Polaris-Text--bodyMd{
    font-size: 14px;
  }
  .Polaris-Label__RequiredIndicator{
    &:after{
      color: red !important;
    }

  }
}

.Polaris-Button--sizeMedium, .Polaris-Button--sizeLarge{
  height: 44px !important;
  padding: 0 16px !important;
  .Polaris-Button__Icon{
    width: 20px;
    height: 20px;
    margin-right: 8px !important;
  }
}
.Polaris-Button--sizeMicro{
  height: 32px !important;
  .Polaris-Text--root{
    font-size: 12px !important;
    padding: 0 12px;
  }
}
.Polaris-Navigation{
  box-shadow: none !important;
}
select, .Polaris-Select{
  border-radius: 8px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.Polaris-TextField{
  input{
    //border: 1px solid rgba(238, 238, 238, 1) !important;
  }
}
button {
  --pc-button-box-shadow: none;
  --pc-button-bg: transparent;
  border-radius: 8px !important;
  padding: 0 12px;
  cursor: pointer !important;

  .Polaris-Button:hover {
    --pc-button-bg: var(--pc-button-bg_hover) !important;
    background-color: var(--pc-button-bg_hover) !important;
    color: #fff !important;
  }
}
.Polaris-Navigation__Text{
  .Polaris-Text--root{
    color: rgba(112, 112, 112, 1) !important;
  }

}
.Polaris-Navigation__ItemInnerWrapper--selected{
  .Polaris-Text--root{
    color: rgba(51, 51, 51, 1) !important;
  }
}
.Polaris-Badge--toneSuccess{
  background-color: rgba(200, 230, 205, 1)!important;
  .Polaris-Text--root{
    font-size: 12px !important;
    color: rgba(62, 141, 75, 1) !important;
  }
}
.Polaris-Badge--toneAttention{
  background-color:rgb(255, 238, 178) !important;
  .Polaris-Text--root{
    font-size: 12px !important;
    color: rgb(184, 130, 27) !important;
  }
}
.Polaris-Button {
  .Polaris-Text--root{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.Polaris-Box {
  overflow: visible !important;
  padding-inline-end: 10px !important;
  border-radius: 8px;
  .Polaris-Text--headingMd{
    font-size: 18px;
  }
}
.FAQ {
  .Polaris-Text--headingMd{
    font-size: 14px;
  }
  .FAQ__category-arrows{
    min-width: 15px;
  }
}
.support__managerWrap{
  .Polaris-Text--headingLg{
    font-size: 18px !important;
    line-height: 22px !important;
    font-weight: 600 !important;
  }
}
.company-settings{
  .Polaris-Text--headingLg{
    font-size: 18px !important;
    line-height: 22px !important;
    font-weight: 600 !important;
  }
  .Polaris-Text--bodySm{
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #2e2e2e !important;
    line-height: 18px !important;
  }
  .Polaris-Text--bodyLg{
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #2e2e2e !important;
  }
  .Polaris-Text--bodyXs{
    font-size: 16px;
    color: rgba(92, 92, 92, 1) !important;
    font-weight: 400;
    line-height: 20px;
  }
  .Polaris-Text--medium{
    font-weight: 400 !important;
  }
  .Polaris-Text--root{
    font-size: 14px;
  }
}

.Polaris-Text--root{
  font-size: 14px;
}

.Polaris-ShadowBevel {
  overflow: visible !important;
  //position: relative;
}
.Polaris-Box,
.Polaris-ShadowBevel,
.Polaris-Card,
.Polaris-Layout {
  overflow: visible !important;
  position: inherit;
}
.ReactModal__Content{
  .Polaris-ShadowBevel{
    position: relative;
  }
}

.Polaris-RadioButton__Input:checked:not([disabled]) + .Polaris-RadioButton__Backdrop, .Polaris-RadioButton__ChoiceLabel:hover .Polaris-RadioButton__Input:checked:not([disabled]) + .Polaris-RadioButton__Backdrop {
  background-color: var(--pc-button-bg_hover)!important;
  border-color: var(--pc-button-bg_hover) !important;
}
.Polaris-ShadowBevel{
  --pc-shadow-bevel-border-radius-xs: 8px !important;
  --p-shadow-bevel-100: transparent !important;
}
.Polaris-Button--variantMonochromePlain{
  border: 1px solid transparent !important;
  background-color: var(--p-color-action-variantMonochromePlain);
  &:hover, &:focus, &:active{
    background-color: var(--p-color-action-variantMonochromePlain);
  }
}
.Polaris-Button--toneCritical{
  border: 1px solid transparent !important;
  margin-left: 10px;
  cursor: pointer !important;
  background-color: var(--p-color-action-variantMonochromePlain);
  &:hover, &:focus, &:active{
    text-decoration: none !important;
    background-color: var(--p-color-action-variantMonochromePlain);
  }
  .Polaris-Text--regular{
    color: #fff !important;
    text-underline: none;
  }
}
.Polaris-Button--variantSecondary{
  border: 1px solid rgba(153, 153, 153, 1) !important;
  background-color: transparent;
  min-width: 100px !important;
}
.Polaris-ActionMenu-SecondaryAction button{
  background: transparent !important;
}
.Polaris-Button--variantSecondary{
  background: transparent !important;
}
.Polaris-Navigation__PrimaryNavigation:hover{
  background-color: transparent;
}
.Polaris-TextField input{
  height: 32px !important;
}

.Polaris-Navigation__ItemWrapper{
  padding: 0;
  .Polaris-Navigation__Item{
    margin: 0 10px;
  }
  .Polaris-Navigation__ItemInnerWrapper--selected{
    border-radius: 0;
    border-right: 3px solid var(--p-color-text-primary);
  }
  .Polaris-Navigation__ItemInnerWrapper:is(:hover, :focus-visible){
    background-color: var(--pc-bg-hover);
  }
  .Polaris-Navigation__Badge{
    background-color: var(--pc-button-bg_hover);
  }
}

.Polaris-Button--variantPrimary, {
  --pc-button-bg: var(--p-color-action-primary) !important;
  border: none !important;
  box-shadow: none !important;
  min-width: 100px !important;
  .Polaris-Text--root{
    color: #ffffff !important;
  }

  &:hover{
    background-color: var(--p-color-action-primary-hover) !important;
    color: #fff !important;
  }
  .Polaris-Icon {
    svg, path {
      stroke: #fff !important;
    }
  }
}
.Polaris-TextField__Input{
  border-radius: 6px;
}

.Polaris-DataTable__Cell {
  font-size: 14px;
  font-weight: 400;
  color:rgba(46, 46, 46, 1)
}
.Polaris-DataTable__Cell--verticalAlignTop {
  vertical-align: middle;
  button{
    padding: 0 5px !important;
  }
}
.Polaris-Header-Title__TitleWrapper{
  .Polaris-Text--root, .Polaris-Text--heading3xl{
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
  }
}
.Polaris-Text--heading3xl{
    font-size: 36px !important;
    font-weight: 600;
    line-height: 44px;
  }
.Polaris-Header-Title__SubTitle{
  color: var(--p-color-text-secondary);
  font-size: 18px;
  font-weight: 400;
  .Polaris-Text--root, .Polaris-Text--bodySm, .Polaris-Text--subdued{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }
}
.Polaris-Text--root, .Polaris-Text--root span {
  color: var(--p-color-text-secondary);
}
.Polaris-Link {
  font-weight: 400;
  font-size: 14px;
  color: var(--a-link);
}
.Polaris-Tabs{
  border-radius: 0;
  &__Wrapper{
    .Polaris-Tabs__Tab{
      border-bottom: 1px solid rgba(232, 236, 242, 1);
      border-radius: 0 !important;
    }
    .Polaris-Text--root{
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      color: rgba(112, 112, 112, 1);
    }
  }
  .Polaris-Tabs__TabContainer{
    background-color: transparent;
  }
  .Polaris-Tabs__Tab--active{
    color: var(--p-color-action-primary);
    background-color: transparent;
    border-bottom: 3px solid var(--p-color-text-primary);
  }
  &__Tab:not([aria-disabled='true']):hover, &__Tab:not([aria-disabled='true']):focus, {
    background-color: transparent;
    color: var(--p-color-action-primary);
  }
}
.Polaris-Choice__Label{
  .Polaris-Text--root{
    color: rgba(46, 46, 46, 1);
    font-size: 14px;
    font-weight: 400;
  }
}
.Polaris-TextField{
  input{
    &::placeholder{
      color: rgba(112, 112, 112, 1);
    }
  }
}
.Polaris-InlineError{
  .Polaris-Icon{
    svg{
      fill: var(--p-color-action-variantMonochromePlain) !important;
    }
  }
  .Polaris-Text--root{
    color: var(--p-color-action-variantMonochromePlain);
  }
}

.Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop, {
  background-color: var(--p-color-action-primary) !important;
  border-color: var(--p-color-action-primary) !important;
  box-shadow: inset 0 0 0 var(--p-space-800) var(--p-color-action-primary);
  cursor: pointer !important;
}

.Polaris-Choice__Control {
  * {
    cursor: pointer !important;
  }
}
.Polaris-ShadowBevel{
  margin-bottom: 20px;
  border: 1px solid rgba(227, 227, 227, 1);
  border-radius: 8px;
}
.Polaris-Frame__Content{
  position: inherit;
}

.bg-red {
  background-color: #fef3f2;
}

.bg-yellow {
  background-color: #fffbec;
}

.Polaris-Button--outline.Polaris-Button--iconOnly {
  border: none !important;
}

.Polaris-Button--primary {
  background-color: var(--p-color-action-primary);
}

.border-top {
  border-top: 1px solid #e1e3e5;
}

.Polaris-DataTable__Cell--verticalAlignTop {
  white-space: nowrap;
}

.Polaris-DataTable__Cell {
  position: relative;
}

.Polaris-DataTable__Cell .ButtonOnHover {
  opacity: 0;
  transition: opacity 0.1s ease;
}

.Polaris-DataTable__Cell:hover .ButtonOnHover {
  opacity: 1;
}

.Polaris-DataTable__Cell .Polaris-Box {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
}

.Polaris-Spinner svg {
  fill: var(--p-color-action-primary);
}

.Polaris-ResourceItem:hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.featureCounterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
  margin: 0 auto;
  button{
    min-width: 50px;
    width: 50px;
    border: none !important;
    box-shadow: none !important;
  }
  .Polaris-Button--variantSecondary{
    min-width: auto !important;
  }
}

.featureCounter {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
  width: fit-content;
  padding: 2px;
  border: solid 1px #aaa;
  border-radius: 3px;
  height: 30px;
  button{
    min-width: auto;
    width: auto;
    padding: 0 !important;
  }
}

.block-white{
  background-color: #fff !important;
  border: 1px solid rgba(227, 227, 227, 1);
  border-radius: 8px;
  padding: 20px;
}

.custom-banner_style{
  button{
    height: 20px !important;
  }
}
.nav-wrapper{
  box-shadow: 0px 2px 5px 0px rgba(67, 105, 139, 0.1);
  .Polaris-Text--bodyMd{
    font-size: 12px;
  }
}
.messages-page{
  .Polaris-Avatar--sizeMd{
    border-radius: 50%;
  }
}
.totals-numbers{
  padding-top: 10px;
  .Polaris-Box{
    margin-top: 10px;
  }
  .Polaris-Text--headingMd{
    font-size: 14px;
  }
}
.card-preview-table{
  .Polaris-DataTable__Cell--verticalAlignTop{
    white-space: wrap;
  }
}
.Polaris-DropZone-FileUpload{
  button, .Polaris-Button--variantSecondary {
    border: none !important;
    box-shadow: none !important;
  }
}